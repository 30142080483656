import { Component, OnInit,Input } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
@Component({
  selector: 'app-alibaba-checkout-cart',
  templateUrl: './alibaba-checkout-cart.component.html',
  styleUrls: ['./alibaba-checkout-cart.component.scss'],
})
export class AlibabaCheckoutCartComponent implements OnInit {
  @Input() cart: any[] = [];
  @Input() product_name;
  constructor(   
    private formservice:FormServiceService,
    private request: RequestService,
  ) { }

  ngOnInit() {

  }
  ngAfterViewInit(){
    console.log('cart', this.cart);
    if(this.cart?.length ==0){
      this.cart=this.formservice.AlibabaCheckoutCart;
      this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,add_items:true, cart_items: this.cart})
      .then((data) => {
        console.log('data',data);
  
     
      });
    }else{
    //nothing as they just want to buy a product inmediatley
    }


  }
  // Calculate the total price of the cart
  calculateTotal(): number {
    return this.cart.reduce(
      (total, item) => total + item.price * item.selectedQuantity,
      0
    );
  }

  // Checkout logic
  checkout(): void {
    console.log('Checkout:', this.cart);
    this.request.createRequestPost('AlibabaProductHandler', { create_order_and_pay: true, cart: this.cart })
    .then((data) => {
      console.log('data', data);
      Swal2.fire({
        title: 'Compra realizada con éxito',
        text: 'Tu orden ha sido creada y procesada correctamente. Gracias por tu compra.',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    })
    .catch((error) => {
      console.error('Error occurred:', error);
      Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un problema al procesar tu solicitud. Por favor, intenta de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    });
  
    // Logic to handle checkout process, e.g., API call or navigation
  }
  removeItem(item){
    this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,delete_items:true, item: item})
    .then((data) => {
      console.log('data',data);
      this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,get_items:true, })
      .then((data) => {
        console.log('data',data);
        this.cart=data.cart_items;
  
     
      });
   
    });
  }
}
