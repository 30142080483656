import { Injectable } from '@angular/core';
import {PublicationService} from "../publications/publication.service";
import {LoadingController, ModalController} from "@ionic/angular";
import Swal from "sweetalert2";
import { request } from 'http';
import { RequestService } from '../request/request.service';
import { PublicationSaveTemplateComponent } from './../../components/publication/publication-save-template/publication-save-template.component';

@Injectable({
  providedIn: 'root'
})
export class ActualizacionServiceService {
  public current_page:number = 1;
  public loading: any;
constructor(
  private publicationService: PublicationService,
  private loadingController:LoadingController,
  private request: RequestService,
  private modalctrl:ModalController
) { }

async showLoading() {
  this.loading = await this.loadingController.create({
    message: '⚡️⚡️⚡️',
    spinner: 'circular',
    cssClass: 'custom-loader',
    backdropDismiss: false,
    translucent: true
  });

  await this.loading.present();

  const loaderElement = document.querySelector('ion-loading') as HTMLIonLoadingElement;
  const spinnerElement = loaderElement.querySelector('.loading-spinner') as HTMLElement;
  
  if (spinnerElement) {
    spinnerElement.style.background = 'black';
    spinnerElement.style.border = '1px solid white';
    spinnerElement.style.borderRadius = '6px';
    spinnerElement.style.backgroundImage = 'url("https://laniakea-multimedia.s3.amazonaws.com/electric.gif")';
    spinnerElement.style.backgroundSize = 'cover';
  }
}





async dismissLoading() {
  try {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    } else {
      console.log('Loading is already dismissed or not initialized.');
    }
  } catch (err) {
    console.log('Error while dismissing the loading:', err);
  }
}

  getPubFromServer(user_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getAllPublications(this.current_page, (user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined));
  }

  async getPublications(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try{
     // if (show_loading == undefined) {
      //  console.log('showing the loading');
        //this.showLoading();
      //}
      if(isrefresh == true || isrefresh == null) {
        this.current_page = 1;
      }
      if (event !== null){
        event.target.complete();
      }
      const data = await this.getPubFromServer((user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from']-1; i<to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('MyFeedList', objectPublication);
        new_publications.push(publication);
      }
    //  if(show_loading == undefined){
      //  this.loading.dismiss();
     //   console.log('hidding the loading');
     // }
      return new_publications;
    }
    catch (err){
    //  this.loading.dismiss();
      console.log('errorr in get publications', err);
      return [];
    }
  }

  
  async getPublicationsSave(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try {
      if (show_loading == undefined) {
        console.log('showing the loading');
        this.showLoading();
      }
      if (isrefresh == true || isrefresh == null) {
        this.current_page = 1;
      }
      if (event !== null) {
        event.target.complete();
      }
      const data = await this.getSavePubFromServer(user_id ? user_id : undefined, publication_type ? publication_type : undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from'] - 1; i < to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('MyFeedList', objectPublication);
        console.log('Publication:', publication);
        new_publications.push(publication);
      }
      if (show_loading == undefined) {
        this.loading.dismiss();
        console.log('hiding the loading');
      }
      return new_publications;
    } catch (err) {
      this.loading.dismiss();
      console.log('error in get publications', err);
      return [];
    }
  }
  

  getSavePubFromServer(user_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getAllSavedPublications(this.current_page, (user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined));
  }
  async getPublicationsGifted(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try {
      if (show_loading == undefined) {
        console.log('showing the loading');
        this.showLoading();
      }
      if (isrefresh == true ) {
        this.current_page = 1;
      }
      if (event !== null) {
        event.target.complete();
      }
      const data = await this.getGiftedPubFromServer(user_id ? user_id : undefined, publication_type ? publication_type : undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from'] - 1; i < to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('COLECCIONREGALOSLIST', objectPublication);
        console.log('Publication:', publication);
        new_publications.push(publication);
      }
      if (show_loading == undefined) {
        this.loading.dismiss();
        console.log('hiding the loading');
      }
      return new_publications;
    } catch (err) {
      this.loading.dismiss();
      console.log('error in get publications', err);
      return [];
    }
  }
  

  getGiftedPubFromServer(user_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getAllGiftedPublications(this.current_page, (user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined));
  }

  async getPublicationsPaid(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try {
      if (show_loading == undefined) {
        console.log('showing the loading');
        this.showLoading();
      }
      if (isrefresh == true ) {
        this.current_page = 1;
      }
      if (event !== null) {
        event.target.complete();
      }
      const data = await this.getPaidPubFromServer(user_id ? user_id : undefined, publication_type ? publication_type : undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from'] - 1; i < to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('COLECCIONCOMPRASLIST', objectPublication);
        console.log('Publication:', publication);
        new_publications.push(publication);
      }
      if (show_loading == undefined) {
        this.loading.dismiss();
        console.log('hiding the loading');
      }
      return new_publications;
    } catch (err) {
      this.loading.dismiss();
      console.log('error in get publications', err);
      return [];
    }
  }
  

  getPaidPubFromServer(user_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getAllPaidPublications(this.current_page, (user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined));
  }

  getAIPubFromServer(user_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getAllAIPublications(this.current_page, (user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined));
  }

  async getAIPublications(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try{
     // if (show_loading == undefined) {
        console.log('showing the loading');
       // this.showLoading();
      //}
      if(isrefresh == true || isrefresh == null) {
        this.current_page = 1;
      }
      if (event !== null){
        event.target.complete();
      }
      const data = await this.getAIPubFromServer((user_id) ? user_id: undefined, (publication_type) ? publication_type: undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      if (publication_type === 'lkfilm'){
        console.log('type1');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MylkfilmList', result[i.toString()]);
          new_publications.push(publication);
        }
      }else if (publication_type === 'earth'){
        console.log('type2');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MyearthList', result[i.toString()]);
          new_publications.push(publication);
        }
      }else if (publication_type === 'epoch'){
        console.log('type3');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MyepochList', result[i.toString()]);
          new_publications.push(publication);
        }
      }else if (publication_type === 'eclipse'){
        console.log('type4');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MyeventosList', result[i.toString()]);
          new_publications.push(publication);
        }
      }else if (publication_type === 'land'){
        console.log('type5');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MylandList', result[i.toString()]);
          new_publications.push(publication);
        }
      }else{
        console.log('type6');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MyFeedList', result[i.toString()]);
          new_publications.push(publication);
        }
      }
    
     // if(show_loading == undefined){
       // this.loading.dismiss();
      //  console.log('hidding the loading');
     // }
      return new_publications;
    }
    catch (err){
     // this.loading.dismiss();
      console.log('errorr in get publications', err);
      return [];
    }
  }
  
  async getUniversalLinkPublications(event: any, isrefresh: boolean, publication_id?: number, show_loading?: boolean, publication_type?: string): Promise<any[]> {
    try {
     // if (show_loading == undefined) {
       // console.log('showing the loading');
       // this.showLoading();
      //}
      if (isrefresh == true ) {
        this.current_page = 1;
      }
      if (event !== null) {
        event.target.complete();
      }
      const data = await this.getUniversalLinkPubFromServer(publication_id ? publication_id : undefined, publication_type ? publication_type : undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from'] - 1; i < to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('MyFeedList', objectPublication);
        console.log('Publication:', publication);
        new_publications.push(publication);
      }
      //if (show_loading == undefined) {
     //   this.loading.dismiss();
    //    console.log('hiding the loading');
    //  }
      return new_publications;
    } catch (err) {
      //this.loading.dismiss();
      console.log('error in get publications', err);
      return [];
    }
  }
  

  getUniversalLinkPubFromServer(publication_id ?: number, publication_type?: string) {
    return Promise.resolve(this.publicationService.getUniversalLinkPublications(this.current_page, (publication_id) ? publication_id: undefined, (publication_type) ? publication_type: undefined));
  }

  async getProfilePublications(event: any, isrefresh: boolean, profile_id?: number, show_loading?: boolean, publication_type?: string, pubSection?:any): Promise<any[]> {
    try {
      //if (show_loading == undefined) {
        //console.log('showing the loading');
        //this.showLoading();
      //}
      if (isrefresh == true ) {
        this.current_page = 1;
      }
      if (event !== null) {
        event.target.complete();
      }
      const data = await this.getProfilePubFromServer(profile_id ? profile_id : undefined, publication_type ? publication_type : undefined,  pubSection ? pubSection : undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
      for (let i = data['from'] - 1; i < to; i++) {
        const objectPublication = result[i.toString()];
        console.log('objectPublication:', objectPublication);
        const publication = await this.publicationService.updatePublicationList('MyFeedList', objectPublication);
        console.log('Publication:', publication);
        new_publications.push(publication);
      }
      //if (show_loading == undefined) {
        //this.loading.dismiss();
        //console.log('hiding the loading');
      //}
      return new_publications;
    } catch (err) {
      //this.loading.dismiss();
      console.log('error in get publications', err);
      return [];
    }
  }
  

  getProfilePubFromServer(profile_id ?: number, publication_type?: string, pubSection?:any) {
    return Promise.resolve(this.publicationService.getProfilePublications(this.current_page, (profile_id) ? profile_id: undefined, (publication_type) ? publication_type: undefined,  pubSection ? pubSection : undefined));
  }
  getPlayslistPubFromServer(user_id ?: number, playlist?: string) {
    return Promise.resolve(this.publicationService.getAllPlaylistPublications(this.current_page, (user_id) ? user_id: undefined, (playlist) ? playlist: undefined));
  }

  async getPlaylistPublications(event: any, isrefresh: boolean, user_id?: number, show_loading?: boolean, playlist?: string): Promise<any[]> {
    try{
     // if (show_loading == undefined) {
        console.log('showing the loading');
       // this.showLoading();
      //}
      if(isrefresh == true || isrefresh == null) {
        this.current_page = 1;
      }
      if (event !== null){
        event.target.complete();
      }
      const data = await this.getPlayslistPubFromServer((user_id) ? user_id: undefined, (playlist) ? playlist: undefined);
      const result = data['data'];
      let new_publications = [];
      let to = data['to'];
    
        console.log('type6');
        for (let i = data['from']-1; i<to; i++) {
          const publication = await this.publicationService.updatePublicationList('MyFeedList', result[i.toString()]);
          new_publications.push(publication);
        }
      
    
     // if(show_loading == undefined){
       // this.loading.dismiss();
      //  console.log('hidding the loading');
     // }
      return new_publications;
    }
    catch (err){
     // this.loading.dismiss();
      console.log('errorr in get publications', err);
      return [];
    }
  }
  
}
