import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'
import { ProtectAccessGuard } from './guards/protect-access.guard'
import { SimpleLoadingStrategy } from './Models/Classes/LoadStrategy/simple-loading-strategy'

let route = '';

route= 'loader';

const routes: Routes = [
  {
    path: '',
    redirectTo: route,
    pathMatch: 'full',
  },

  // {
  //   path: 'profile',
  //   loadChildren: () =>
  //     import('./pages/profile/profile.module').then(
  //       (m) => m.ProfilePageModule,
  //     ),
  //   data: {
  //     preload: true,
  //   },
  //   canActivate: [ProtectAccessGuard],
  // },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/principal/home/home.module').then(
        (m) => m.HomePageModule,
      ),
    data: {
      preload: true,
    },
    //canActivate: [ProtectAccessGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('../app/pages/search-page/search-page.module').then(
        (m) => m.SearchPagePageModule,
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/principal/auth/login/login.module').then(
        (m) => m.LoginPageModule,
      ),
   // canActivate: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/principal/auth/register/register.module').then(
        (m) => m.RegisterPageModule,
      ),
    //canActivate: [AuthGuard],
  },
  {
    path: 'waiting-page',
    loadChildren: () =>
      import('./pages/principal/start/waiting-page/waiting-page.module').then(
        (m) => m.WaitingPagePageModule,
      ),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./pages/principal/auth/verification/verification.module').then(
        (m) => m.VerificationPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'publication-feed',
    loadChildren: () =>
      import('./pages/publication-feed/publication-feed.module').then(
        (m) => m.PublicationFeedPageModule,
      ),
  },
  {
    path: 'create-publication',
    loadChildren: () =>
      import('./pages/create-publication/create-publication.module').then(
        (m) => m.CreatePublicationPageModule,
      ),
  },
  {
    path: 'chat-page',
    loadChildren: () => import('./pages/chat/chat-page/chat-page.module').then( m => m.ChatPagePageModule)
  },

  {
    path: 'land-servicios-view',
    loadChildren: () => import('./pages/ecosistemas/land-servicios/land-servicios-view/land-servicios-view.module').then( m => m.LandServiciosViewPageModule)
  },
  {
    path: 'eventos-eclipse-view',
    loadChildren: () => import('./pages/ecosistemas/Eventos-Eclipse/eventos-eclipse-view/eventos-eclipse-view.module').then( m => m.EventosEclipseViewPageModule)
  },
  {
    path: 'educacion-epoch-view',
    loadChildren: () => import('./pages/ecosistemas/Educacion-Epoch/educacion-epoch-view/educacion-epoch-view.module').then( m => m.EducacionEpochViewPageModule)
  },
  {
    path: 'hospedaje-earth-view',
    loadChildren: () => import('./pages/ecosistemas/Hospedaje-Earth/hospedaje-earth-view/hospedaje-earth-view.module').then( m => m.HospedajeEarthViewPageModule)
  },
  {
    path: 'terminos-condiciones-view',
    loadChildren: () => import('./pages/Terminos-Condiciones/terminos-condiciones-view/terminos-condiciones-view.module').then( m => m.TerminosCondicionesViewPageModule)
  },
  {
    path: 'chat-page',
    loadChildren: () => import('./pages/chat/chat-page/chat-page.module').then( m => m.ChatPagePageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'publication-save',
    loadChildren: () => import('./pages/save-publication/publication-save/publication-save.module').then( m => m.PublicationSavePageModule)
  },
  {
    path: 'messages-page',
    loadChildren: () => import('./pages/messages-page/messages-page.module').then( m => m.MessagesPagePageModule)
  },
  {
    path: 'voice-notes',
    loadChildren: () => import('./voice-notes/voice-notes.module').then( m => m.VoiceNotesPageModule)
  },
  {
    path: 'dropship-product-selection',
    loadChildren: () => import('./dropship-product-selection/dropship-product-selection.module').then( m => m.DropshipProductSelectionPageModule)
  },







]

@NgModule({
  providers: [SimpleLoadingStrategy],
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: SimpleLoadingStrategy }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
