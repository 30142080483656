//import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
//import { Landservice3Component } from './../../publication/create/landservice/landservice3/landservice3.component';
//import { CalendaryComponent } from './../../calendar/calendary/calendary.component';
//import { Landservice7Component } from './../../publication/create/landservice/landservice7/landservice7.component';


import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ActionSheetController, MenuController, ModalController, NavController } from '@ionic/angular';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { Utils } from 'src/app/Models/Classes/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

import { ConfiguracionComponent } from '../configuracion/configuracion.component';
import { WelcomeMessageComponent } from '../welcome-message/welcome-message.component';
//import { Epoch2Component } from '../../publication/create/epoch/epoch2/epoch2.component';
import { CameraServiceService } from './../../../services/multimedia/camera-service.service';
import Swal2 from 'sweetalert2';
import { UploadMultimediaService } from './../../../services/upload/upload-multimedia.service';
import {RequestService} from "../../../services/request/request.service";
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Capacitor } from '@capacitor/core';
import {isImage,isVideo} from "../../../utils";
import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
//import { resolve } from 'dns';
//import { rejects } from 'assert';
//import { PagarCComponent } from './../../pagar-c/pagar-c.component';
//import { PagoCompletadoComponent } from './../../publication/create/pago-completo/pago-completado/pago-completado.component';
//import { MetodoDePagoComponent } from './../../publication/create/finalizar-compra/metodo-de-pago/metodo-de-pago.component';
//import { VerificacionDeCuentaComponent } from './../verificacion-de-cuenta/verificacion-de-cuenta.component';
//import { PagoVerificacionComponent } from './../../pago-verificacion/pago-verificacion.component';
//import { LkfilmViewBySlidesComponent } from './../../lkfilm-view-by-slides/lkfilm-view-by-slides.component';
//import { SliderInformationComponent } from './../../slider-information/slider-information.component';
//import { ReconoceridComponent } from './../../slider-information/reconocerid/reconocerid.component';
//import { PagoTarjetaComponent } from './../../pago-verificacion/Pago-Tarjeta/Pago-Tarjeta.component';
import { ModulesLockComponent } from './../../go-ecosystem/modules-lock/modules-lock.component';
//import { PrincipalPublicationComponent } from './../../publication/create/principal-components/principal-publication/principal-publication.component';
//import { CumuloSubscriptionComponent } from '../../publication/create/cumulos-suscription/cumulos/cumulo-subscription/cumulo-subscription.component';
//import { VerDetallesComponent } from './../../publication/create/Ver-detalles/Ver-detalles.component';
//import { PagosPorPublicacionComponent } from './../../publication/create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';
//import { RegaloModalComponent } from './../Regalo-Modal/Regalo-Modal.component';
//import { FilterBar1Component } from '../../searching/filter-bar1/filter-bar1.component';
//import { FilterBarComponent } from './../Filter-Bar/Filter-Bar.component';
import { PublicationSavePage } from './../../../pages/save-publication/publication-save/publication-save.page';
//import { CumuloSubscription2Component } from './../../publication/create/cumulos-suscription/cumulos/cumulo-subscription2/cumulo-subscription2.component';
//import { InformationComponent } from './../../publication/publication-type/normal-publication/information/information.component';
//import { SinResultadosComponent } from './../sin-resultados/sin-resultados.component';
//import { RegisterPage } from './../../../pages/principal/auth/register/register.page';
import { PublicationFeedPage } from 'src/app/pages/publication-feed/publication-feed.page';
import {ColeccionLaniakea} from'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/ColeccionLaniakea';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import {ColeccionPremium} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-premium/Coleccion-premium';
import {ColeccionRegalos} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-regalos/Coleccion-regalos';
import { ChangeDetectorRef } from '@angular/core';

import { QrCodeGeneratorComponent} from 'src/app/qr-code-generator/qr-code-generator.component';
import { QrCodeCollectionComponent} from 'src/app/qr-code-collection/qr-code-collection.component';
import { ShootingStar4Component} from 'src/app/components/publication/create/shooting-star/shooting-star4/shooting-star4.component';
import { AppComponent } from 'src/app/app.component';
import {GooglemapsComponent} from 'src/app/components/googlemaps/googlemaps.component';
import {StatsComponent} from 'src/app/stats/stats.component';
import {PlaylistsComponent} from 'src/app/playlists/playlists.component';
import { PopoverController } from '@ionic/angular';
import {CarritoDeComprasComponent} from 'src/app/carrito-de-compras/carrito-de-compras.component';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
import { Browser } from '@capacitor/browser';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
import {FeexOrdersComponent} from 'src/app/feex-orders/feex-orders.component';
@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss'],
})

export class MainMenuComponent implements OnInit {
  public HubbleExpired: boolean = false;
  public Coleccion:boolean=false;
  type:any;
  mediaType: string;
	imagecam:any;
	profileRut:string;
  profile_id:any;
	public multimedia: Array<MediaItem> = new Array<MediaItem>();
	@Input() profile_photo: string = null;
	@Input() complete_name: string;
	@Input() username: string;
	@Input() RolStateApp: string = "Ambos";
	@Input() RolAppList: Array<string> = ["Social", "Ambos", "Profesional"];
	dark: boolean = true;
	@Input() urlVersion: string;
	@Input() publication: PublicationClass;

	constructor(
		private navCtrl: NavController,
		private menu: MenuController,
		private authService: AuthService,
		private utils: Utils,
		private nativeStorage: NativeStorage,
		private chatService: ChatService,
		private profile_service: ProfileService,
		private modalController: ModalController,
		private router: Router,
		private camera: CameraServiceService,
		private uploadMedia: UploadMultimediaService,
		private actionSheetController: ActionSheetController,
		private formservice:FormServiceService,
    private ref: ChangeDetectorRef,
    	private request: RequestService,
      public app :  AppComponent,
      private popoverCtrl:PopoverController,
      private actualizacionservice:ActualizacionServiceService,
    
	) {
		const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
		this.dark = prefersDark.matches;
	}
	ngOnInit() {
		this.getProfile();


	}
  OpenFeexOrders(){
    this.modalController.create({
			component: FeexOrdersComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
			
			}
		}).then((modal) => {
			modal.present();
		}).catch((error) => {
			console.error(error);
		});
  }
  openComponentVideo(fetchedPublications){
		
    this.formservice.mostrar = false;
   this.formservice.publications=fetchedPublications;
   //this.formservice.valueSelect='playlist';
   this.formservice.valueSelect='MENU';
    this.formservice.mediaTemplate=true;
  
    }
  async carritoDeCompras(){
    this.menu.close();
    this.formservice.actual_playlist='carrito';
    this.router.navigate(['/home/ecosystem-page']);
    const publications_from_server = await this.actualizacionservice.getPlaylistPublications(null, false, null, false, this.formservice.actual_playlist);
    const fetchedPublications = publications_from_server || [];
   
    this.openComponentVideo(fetchedPublications);
    /*
    this.modalController.create({
			component: CarritoDeComprasComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {

			}
		}).then((modal) => {
			modal.present();
		}).catch((error) => {
			console.error(error);
		});*/
  }
  askForAuthentifactionCapture(take_photo: boolean = true, take_video: boolean = true, pick_media: boolean = true,pick_video: boolean = true) {
		Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(take_photo) ?
					{
						text: 'Iniciar sesión',
						icon: 'log-in',
						handler: () => {
					this.login();
						}
					} : null,
				(take_video) ?
					{
						text: 'Crear nueva cuenta.',
						icon: 'person-add',
						handler: () => {
              this.router.navigate(['/register']);
						}
					} : null,
				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
            this.actionSheetController.dismiss();
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: 'Elige una opción:',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
	}

  login(){
   if(!this.username){
    this.router.navigate(['/login']);
   }else{
    Swal2.fire({
      title: 'Inicio de sesión',
      text: `Al aceptar, se cerrará tu sesión de ${this.username}, y deberás volver iniciar sesión.`,
      icon: 'info',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
    	background: '#010f4e',
	  }).then((result) => {
		if (result.isConfirmed) {
      this.logout_button();
		}
	});

   }
    
  }
	theme() {
		this.dark = !this.dark;
		document.body.classList.toggle('dark');
	}
	getProfile() {
    this.profile_service.getProfileInfo().then((data) => {
      data = JSON.parse(data);
      this.profile_photo = data.profile_photo.url;
      this.profile_id = data.id;
      this.complete_name = data.name + data.last_name;
      this.username = data.username;
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
  
    });
  }  
	change_type(value) {
		this.RolStateApp = value;

	}
	click_on_profile() {
		this.navCtrl.navigateForward('home/profile');
		this.menu.close();
	}

	logout_button() {
		/* Loading Mensaje */
		this.utils.loadingPresent().then(() => {
			this.authService.logout().finally(() => {
				this.chatService.logoutSuscribers();
        if (Capacitor.getPlatform() !== 'web'){
				this.nativeStorage.clear();
        }else{
          localStorage.clear();
        }
				this.utils.loadingDismiss();
        this.router.navigate(['/login']);
       
				//this.navCtrl.navigateRoot('starting-page');
				//window.location.reload();

			});
		})

	}

	click_on_saved() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				filter: "Guardados",
			}
		};
		this.navCtrl.navigateForward('home/search', navigationExtras);
		this.menu.close();
	}

	openWelcomeModal(username) {
		this.modalController.create({
			component: WelcomeMessageComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				username: username
			}
		}).then((modal) => {
			modal.present();
		}).catch((error) => {
			console.error(error);
		});
	}

	OpenUser(App: String) {
		switch (App) {
			case "Configuracion":
        this.menu.close();
				this.modalController.create({
					component: ConfiguracionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            profile_id: this.profile_id,
          }
				}).then((modal) => {
					modal.present();
				}).catch((error) => {
					console.error(error);
				});
				break;
			case 'Users':
        if (this.formservice.profile){
          this.navCtrl.navigateForward('search');
        }else{
          Swal2.fire({
            title: 'Debes iniciar sesión',
            text: 'Porfavor inicia sesión para comenzar a entrelazar con algún usuario.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          }).then(() => {
          
            console.log("Alert displayed successfully.");
          }).catch((error) => {
            console.error("Error displaying alert:", error);
          });
        }
			
				break;

		}

	}
  async Paynet(){
    let  url= 'https://www.paynet.com.mx/mapa-tiendas/index.html';
    if (Capacitor.getPlatform() === 'web') {
    window.open(url, '_blank');
    }else{
      await Browser.open({ url });
    }
  
  
  }
	setMultimedia(mediaItem: MediaItem) {
		return new Promise ((resolve,rejects)=> {
      this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
      this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
        then((result)=>{
        resolve(true);
      });
    });
  }

//	askForMediCapture(take_photo: boolean = true,pick_media: boolean = true) {
  //  let botonesDisponibles = [
  //    (take_photo) ?
    //    {
   //       text: 'Tomar una Foto',
   //       icon: 'camera',
  //        handler: () => {
  //          this.camera
   //         .takePhoto()
  //          .then((mediaItem) => {
  //            this.setMultimedia(mediaItem).
    //            then((result)=>{
  //                if(isImage(this.uploadMedia.image)==false){
  //                    Swal2.fire({
  //                      title: 'Imagen de perfil erronea',
  //                      text: 'Asegurate de usar una imagen',
    //                    icon: 'warning',
   //                     confirmButtonText: 'Entendido',
     //                   color: '#ffffff',
       //                 background: '#010f4e',
     //                 });
     //             }else{
     //               this.uploadMedia.post().then((r)=>{
     //                 if(!r[1].includes('image')){
     //                 }else{
     //                   this.profile_photo= r[0];
     //                   this.postProfilePict();
     //                 }
     //               }).catch((error)=>{
     //                 console.log(error);
     //               });
     //             }
     //           });
     //       })
     //       .catch((error) => {
     //         console.error(error);
     //       });
     //     }
     //   } : null,
     //   (pick_media) ?
     //   {
     //     text: 'Seleccionar desde mi galería',
     //     icon: 'images',
     //     handler: () => {
     //       this.uploadMedia.image = null;
    //        this.uploadMedia.pickmultimedia().
    //          then((result)=>{
   //           if(isImage(this.uploadMedia.image)==false){
   //             Swal2.fire({
   //               title: 'Imagen de perfil erronea',
   //               text: 'Asegurate de usar una imagen',
   //               icon: 'warning',
   //               confirmButtonText: 'Entendido',
   //               color: '#ffffff',
  //                background: '#010f4e',
   //             });
   //           }else{
   //             this.uploadMedia.post().then((r)=>{
   //               if(!r[1].includes('image')){
   //               }else{
   //                 this.profile_photo= r[0];
   //                 this.postProfilePict();
   //               }
   //             }).catch((error)=>{
   //               console.log(error);
   //             });
   //           }
   //         });
   //       }
   //     } : null,


    //  {
    //    text: 'Cancelar',
   //     icon: 'close',
   //     role: 'cancel',
   //     handler: () => {
   //       //reject(false);
   //       //console.log('Cancel clicked');
  //      }
  //    }
  //  ];

 //   botonesDisponibles = botonesDisponibles.filter((boton) => {
  //    if (boton != null) {
  //      return true;
  //    }
  //    return false;
 //   });


  //  this.actionSheetController.create({
  //    header: '¿Desde donde deseas añadir multimedia?',
 //     cssClass: 'options-sheet',
 //     buttons: botonesDisponibles
 //   }).then((actionSheet) => {
 //     actionSheet.present();
 //   });

	//}

  postProfilePict2(){
    const requestBody = {
      route: this.profile_photo,
      size:0,
      type:"imagen",
      publication_type:"profile_photo",
      profile_id:this.profile_id
    };
    this.request.createRequestPost('profile/multimedia',requestBody).
      then(()=>{
    }).catch((error)=>{
      console.log("posting new photo profile error",error);
    });
  }

  Guardado(){
    this.formservice.sectionVer="guardado"
	this.modalController.create({
	  component: PublicationFeedPage,
    cssClass: 'detailsInModal',
    swipeToClose: true,
	}).then((modal)=>{
	  modal.present();
	});
  }

  cumulosuscription(){
    this.modalController.create({
      swipeToClose: true,
      component: ModulesLockComponent,
      cssClass: 'detailsInModal',
    }).then((modal)=>{
      modal.present()
    })

  }
  async AbrirPublicacionesGuradadas() {
    this.menu.close();
    const modal = await this.modalController.create({
      swipeToClose: true,
      component: PublicationSavePage,
      cssClass: 'detailsInModal',
      componentProps: {
        profile_id: this.profile_id,
      }
    });

    return await modal.present();
  }
  AbrirColeccion(){
    this.Coleccion=true;
    /*
    this.modalController.create({
      swipeToClose: true,
      component: ColeccionLaniakea,
      componentProps: {
        profile_id: this.profile_id,
    
      }
    }).then((modal)=>{
      modal.present()
    })
    */

  }
  update_photo_profile( text_to_image: boolean = true,pick_media: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
    this.type= 'profile_photo';
   console.log('type in upadte_photo', this.type );
   return new Promise((resolve, reject) => {
     let botonesDisponibles = [
      (text_to_image) ?
      {
        text: 'Crear foto cuántica',
        icon: 'images',
        handler: () => {
          this.AI()
            .then((mediaItem) => {
              resolve([mediaItem,'camera']);
      
                });
    
        }
      } : null,
       (pick_media) ?
         {
           text: 'Seleccionar desde mi galería',
           icon: 'camera',
           handler: () => {
             //this.uploadMedia.image = null;
             this.pickImage().
             then((result)=>{
               resolve([result,'library']);
      
                         
             });
           }
         } : null,


       {
         text: 'Cancelar',
         icon: 'close',
         role: 'cancel',
         handler: () => {
           //reject(false);
           //console.log('Cancel clicked');
         }
       }
     ];

     botonesDisponibles = botonesDisponibles.filter((boton) => {
       if (boton != null) {
         return true;
       }
       return false;
     });


     this.actionSheetController.create({
       header: '¿Desde donde deseas añadir multimedia?',
       cssClass: 'options-sheet',
       buttons: botonesDisponibles
     }).then((actionSheet) => {
       actionSheet.present();
     });
  
 });

 }
 public async AI(){
  this.menu.close();
  // Use the Router to navigate to the "messages-page"
  this.router.navigate(['home/messages-page']);
  this.formservice.fromCreatePublication=true;
  this.router.navigateByUrl('home/messages-page', { skipLocationChange: true }).then(() => {
    this.router.navigate([this.router.url]);
  });
}
 async pickImage(){
  console.log('type in pickimage', this.type );
  //const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
  try {
    const options: CameraOptions = {
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
    };
    console.log('About to call Camera.getPhoto()');
    const media = await Camera.getPhoto(options);

    if (media) {
      console.log('Media picked successfully:', media);
      const imageUrl = Capacitor.convertFileSrc(media.path);
   // imagePreviewElement.src = imageUrl;
      this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
      this.ref.detectChanges();
      const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';

      // Read the file as a Blob
      const response = await fetch(media.webPath);
      const blobfile = await response.blob();

      // Log the Blob for debugging purposes
      console.log('fileBlob:', blobfile);
      if (Capacitor.getPlatform() !== 'web') {
        this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
  }else{
    const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    this.uploadMedia.filename = currentTime + '.png';
  }

      this.uploadMedia.post(blobfile,  this.uploadMedia.filename ).then(({ link, mimeType }) => {
        const ruta = link;
        console.log('ruta', ruta);
        let pub_type = '';
        if(mimeType.includes("image")) {
          pub_type= 'imagen';
        }else if(mimeType.includes("video")){
          pub_type= 'video';
        }
        //this.profile_photo= r[0];
        this.postProfilePict( ruta, pub_type);

       }).catch((error)=>{
         console.log(error);
       });
     
    
      
    }
  } catch (error) {
    console.error('Error picking media:', error);
  }
}  
postProfilePict( ruta, pub_type,){
  console.log('type in postprofilepict', this.type);
  console.log('type in postProfilePict', this.type);
  console.log('ruta in postProfilePict', ruta);
  console.log('pub_type in postProfilePict', pub_type);
  const requestBody = {
    route: ruta,
    size: 800,
    type: 'imagen',
    publication_type: this.type,
    id:this.profile_id,
  };
  this.request.createRequestPost('profileMultimedia', requestBody)
  .then(() => {
    Swal2.fire({
      title: 'Foto actualizada con éxito',
      icon: 'success',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
  })
  .catch((error) => {
    console.log("posting new photo profile error", error);
  })
  .finally(() => {
    this.profile_service.getProfileInfo().then((data) => {
      this.profile_photo = data.profile_photo.url;
      this.profile_id = data.id;
      this.complete_name = data.name + data.last_name;
      this.username = data.username;
    });
  });
}  


sectionPublication(section:string){
  switch (section) {
    case "de-megusta":
      this.menu.close();
      //this.formservice.sectionVer=section
      this.modalController.create({
        component: PublicationFeedPage,
        cssClass: 'detailsInModal',
        swipeToClose: true,

      }).then((modal)=>{
        modal.present();
      });


      break;
    case "Regalos":
      this.menu.close();
     // this.formservice.sectionVer=section
      this.modalController.create({
        component: ColeccionRegalos,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          profile_id: this.profile_id,
      
        }
      }).then((modal)=>{
        modal.present();
      });


      break;
    case "pago-publicacion":
      this.menu.close();
    //this.formservice.sectionVer=section
    this.modalController.create({
      component: ColeccionPremium,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        profile_id: this.profile_id,
    
      }
    }).then((modal)=>{
      modal.present();
    });


    break;

    //case "cumulos":
      //this.formservice.sectionVer=section
      //this.modalController.create({
        //component: PublicationFeedPage
      //}).then((modal)=>{
        //modal.present();
      //});


      //break;

    default:
      Swal2.fire({
        title: 'No se encontro la sección',
        text: "Error ",
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        });
      break;
  }

}



async watchQR(){
  this.menu.close();
  const modal = await this.modalController.create({
    swipeToClose: true,
    component: QrCodeCollectionComponent,
    cssClass: 'detailsInModal',
    componentProps: {
      profile_id: this.profile_id,
      scan: true
    }
  });

  return await modal.present();
}
async openShootingStarsOrders(){
  this.menu.close();
  const modal = await this.modalController.create({
    swipeToClose: true,
    component: ShootingStar4Component,
    cssClass: 'detailsInModal',
    componentProps: {
      profile_id: this.profile_id,
      scan: true
    }
  });

  return await modal.present();
}



async scan(){
  /*if (Capacitor.getPlatform() === 'web') {
    Swal2.fire({
      title: 'Función no disponible',
      text: 'Función solo disponible en aplicación de Play Store o App Store.',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      background: '#010f4e',
    });
  }else{*/
  this.menu.close();
  this.formservice.scanActive=true;
    const modal = await this.modalController.create({
      swipeToClose: true,
      component: QrCodeGeneratorComponent,
      componentProps: {
        profile_id: this.profile_id,
        scan: true
      }
    });
    return await modal.present();   
 // }
}
back(){
this.Coleccion=false;
}

getCarteraHubble(shortcut?) {
  this.router.navigate(['/home/go-ecosystem-page']);  
	Swal2.fire({
		title: 'Procesando...',
		text: 'Hubble está procesando.',
		icon: 'success',
		timer: 9900, // Show the alert for 3.9 seconds
		timerProgressBar: true,
		toast: true,
		position: 'top-end',
		showConfirmButton: false
	  });
    if (this.app.SubscriptionExpired !== true){
	if (!this.HubbleExpired){
		this.HubbleExpired = true;
		this.request.createRequestGet("Hubble", null, { id: this.profile_id })
		.then((result) => {
		  if (result && result.length > 0) {
			console.log("Successfully fetched Hubble info.");
			console.log("Hubble info:", result);
	
			const wallets = JSON.parse(result);
			const transactions = wallets[0].transactions;
	
			const balancesArray = wallets.map((wallet) => wallet.balance);
	
			this.modalController.create({
			  component: WalletAppComponent,
        cssClass: 'detailsInModal',
			  swipeToClose: true,
			  componentProps: {
          shortcut:shortcut,
				profile_id: this.profile_id,
				transactions: transactions,
				balances: balancesArray,
			  },
			}).then((modal) => {
				
			  modal.present();
			  this.HubbleExpired = false;
			});
		  } else {
			console.log("No results found.");
		  }
		})
		.catch((error) => {
		  Swal2.fire({
			  title: 'Hubo un error al abrir la cartera...',
			  text: 'Intenta de nuevo más tarde.',
			  icon: 'error',
			  timer: 3600, // Show the alert for 2 seconds
			  timerProgressBar: true,
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false
			});
		  console.error("Error fetching Hubble info:", error);
		});
	}
}else if (this.app.SubscriptionExpired === true){
  Swal2.fire({
    title: 'Hubo un error al abrir la cartera...',
    text: 'Tu suscripción ha caducado, reneuévala para continuar en la cartera Hubble.',
    icon: 'error',
    timer: 3600, // Show the alert for 2 seconds
    timerProgressBar: true,
    toast: true,
    position: 'top-end',
    showConfirmButton: false
  });
  }
	
  }
  async getBBVALocations(shortcut){
    const modal = await this.modalController.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: InlineBowsingComponent,
			componentProps: {
				url: 'https://portal.bbva.mx/aplicativos/buscador-sucursales/index.jsp',
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
    /*
    this.modalController.create({
      swipeToClose: true,
      component: GooglemapsComponent,
      cssClass: 'detailsInModal',
      componentProps: {
        BBVA_locations: shortcut,
        showDismiss:true
      }
    }).then((modal)=>{
      modal.present()
    });*/
  }
  async STATS(ev:any){
      
    const popover = await this.popoverCtrl.create({
      component: StatsComponent, // Replace with your component for observations
      translucent: true,
      event: ev,
      componentProps: {
        menu:true
      },
      backdropDismiss: true, // Allow dismissal by tapping outside the popover
    });
  
    await popover.present();
  }
  async Playlists(ev:any){
      
    const popover = await this.popoverCtrl.create({
      component: PlaylistsComponent, // Replace with your component for observations
      translucent: true,
      event: ev,
      componentProps: {

      },
      backdropDismiss: true, // Allow dismissal by tapping outside the popover
    });
  
    await popover.present();
  }
 
}
