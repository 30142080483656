import { Component, OnInit, Input } from '@angular/core';
import {AlibabaCheckoutCartComponent}  from 'src/app/alibaba-checkout-cart/alibaba-checkout-cart.component';
import {
  NavController,
  AlertController,
  ModalController, IonicModule, ActionSheetController, IonSelect, IonSlides
} from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
  selector: 'app-stock-keeping-unit-selection',
  templateUrl: './stock-keeping-unit-selection.component.html',
  styleUrls: ['./stock-keeping-unit-selection.component.scss'],
})
export class StockKeepingUnitSelectionComponent implements OnInit {
  @Input() data;
  @Input() product_id;
  @Input() product_name;
  @Input() product_details;
  cart: any[] = [];
  constructor(
    public modalController: ModalController,
    private formservice:FormServiceService,
  ) { }

  ngOnInit() {
    if(!this.product_details){
      this.data=this.data.success.aliexpress_ds_product_get_response.result.ae_item_sku_info_dtos
    }
   
    console.log('data', this.data)
  }
  updateCart(skuGroup: any, sku_image): void {
    // Ensure the `cart` array exists and is initialized
    if (!this.cart) {
      this.cart = [];
    }

    // Ensure the `sku_id` exists and `selectedQuantity` is valid
    if (!skuGroup.sku_id || skuGroup.selectedQuantity == null) {
      return;
    }

    // Find the existing item in the cart
    const existingItem = this.cart.find((item) => item.sku_id === skuGroup.sku_id);

    if (skuGroup.selectedQuantity > 0) {
      if (existingItem) {
        // Update the quantity if the item is already in the cart
        existingItem.selectedQuantity = skuGroup.selectedQuantity;
      } else {
        // Add the item to the cart if it doesn't exist
        this.cart.push({
          sku_id: skuGroup.sku_id,
          price: skuGroup.offer_sale_price,
          selectedQuantity: skuGroup.selectedQuantity,
          product_id:this.product_id,
          item_image:sku_image,
          product_name:this.product_name,
          sku_attr:skuGroup.sku_attr

        });
      }
    } else {
      // Remove the item from the cart if quantity is 0
      this.cart = this.cart.filter((item) => item.sku_id !== skuGroup.sku_id);
    }

    console.log(this.cart); // Debugging: Log the updated cart
  }

  // Method to handle increment and decrement buttons
  updateQuantity(skuGroup: any, change: number, sku_image): void {
    // Ensure `selectedQuantity` is initialized
    if (skuGroup.selectedQuantity == null) {
      skuGroup.selectedQuantity = 0;
    }

    const newQuantity = skuGroup.selectedQuantity + change;

    // Validate the new quantity against stock limits
    if (newQuantity >= 0 && newQuantity <= skuGroup.ipm_sku_stock) {
      skuGroup.selectedQuantity = newQuantity;
      this.updateCart(skuGroup, sku_image);
    }
  }
    // Calcular el total de la compra
    calculateTotal(){
      return this.cart?.reduce(
        (total, item) => total + item.price * item.selectedQuantity,
        0
      );
    }
  
    // Método para manejar el checkout
    agregar_al_carrito(): void {
      this.cart.forEach((newItem) => {
        // Find the index of the existing item in AlibabaCheckoutCart
        const existingIndex = this.formservice.AlibabaCheckoutCart.findIndex(
          (item) => item.sku_id === newItem.sku_id && item.product_id === newItem.product_id
        );
      
        if (existingIndex !== -1) {
          // If the item exists, update its quantity
          const existingItem = this.formservice.AlibabaCheckoutCart[existingIndex];
          this.formservice.AlibabaCheckoutCart[existingIndex] = {
            ...existingItem,
            selectedQuantity: existingItem.selectedQuantity + newItem.selectedQuantity,
          };
        } else {
          // If the item doesn't exist, add it as a new item
          this.formservice.AlibabaCheckoutCart.push(newItem);
        }
      });
      


      console.log('Productos seleccionados:', this.cart);
      this.modalController.create({
        component: AlibabaCheckoutCartComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
       

        }
    }).then((modal)=>{
        modal.present();
    });
      // Aquí puedes redirigir al usuario o realizar la lógica necesaria para procesar el pedido.
    }
    comprar_ahora(){
      console.log('Productos seleccionados:', this.cart);
      this.modalController.create({
        component: AlibabaCheckoutCartComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          cart: this.cart,

        }
    }).then((modal)=>{
        modal.present();
    });
    }
  
}
