import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'app-sort-prodcuts-options-popover',
  templateUrl: './sort-prodcuts-options-popover.component.html',
  styleUrls: ['./sort-prodcuts-options-popover.component.scss'],
})
export class SortProdcutsOptionsPopoverComponent implements OnInit {
  selectedSortOption: string | null = null;
  selectedLanguage: string | null = null;
  constructor(private popoverController: PopoverController) {}
  ngOnInit() {}
  saveSelections() {
    this.popoverController.dismiss({
      sortOption: this.selectedSortOption || 'desc',
  language: this.selectedLanguage || 'es',
    });
  }



}
